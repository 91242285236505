<template>
    <div id="widget">
        <Header :school-slug="school"></Header>
        <div class="business-scroller">
            <BusinessLogo
                v-for="(business, index) in businesses"
                :key="index"
                :business="business"
            />
        </div>
        <Button
            class="minibat-button"
            :url="`https://www.oabat.de/schule/${school}/minibat`"
        >
            Zu unserer Stellenbörse
            <Icon>{{ mdiArrowRight }}</Icon>
        </Button>
    </div>
</template>

<script>
import Header from "@/components/Header"
import BusinessLogo from "@/components/BusinessLogo"
import Button from "@/components/Button"
import Icon from "@/components/Icon"
var mdiArrowRight =
    "M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"

export default {
    name: "MinibatWidget",
    components: {
        Header,
        BusinessLogo,
        Button,
        Icon,
    },
    mounted() {
        this.httpGetAsync(
            `https://www.oabat.de/api/search?category=Unternehmen&channel=${this.school}`,
            (response) => {
                response = JSON.parse(response)
                this.businesses = response.result.data
            }
        )
    },
    props: {
        school: {
            type: String,
            default: "s-mittelschule-passau-st-nikola", // "mittelschule-passau-st-nikola",
        },
    },
    methods: {
        httpGetAsync(theUrl, callback) {
            var xmlHttp = new XMLHttpRequest()
            xmlHttp.onreadystatechange = function () {
                if (xmlHttp.readyState == 4 && xmlHttp.status == 200)
                    callback(xmlHttp.responseText)
            }
            xmlHttp.open("GET", theUrl, true) // true for asynchronous
            xmlHttp.send(null)
        },
    },
    data: () => ({
        mdiArrowRight,
        businesses: new Array(8),
    }),
}
</script>

<style>
/** General CSS & Variables */
#widget {
    --oabat-widget--shadow: 0 2.5pt 7.5pt 0 #00295e26;
    --oabat-widget--height: 400px;
    --oabat-widget--width: 330px;

    scrollbar-color: #000 #ddd;
    font-family: europa, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
}
#widget {
    width: var(--oabat-widget--width);
    height: var(--oabat-widget--height);
    border-radius: 35px;
    box-shadow: var(--oabat-widget--shadow);
    background: white;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.business-logo {
    margin: 5px;
}

.business-scroller {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    justify-content: space-around;
    padding: 0px 15px 10px 15px;
    margin: 0px 5px;
    overflow: auto;
    flex-grow: 1;
}
.icon__svg {
    height: 24px;
    width: 24px;
    fill: currentColor;
}
.minibat-button {
    margin: 7px;
}

/** Webkit scrollbar */
::-webkit-scrollbar {
    width: 7px;
}
::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
    background: #ddd;
    border-radius: 10px;
    margin: 10px 0;
}
::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-clip: padding-box;
    background-color: #000;
    border: 1px solid rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-thumb:hover {
    background-color: #444;
}
</style>
