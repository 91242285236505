<template>
    <span class="oabat-icon">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            role="img"
            aria-hidden="true"
            class="oabat-icon__svg"
        >
            <path :d="path"></path></svg
    ></span>
</template>

<script>
export default {
    computed: {
        path() {
            return this.$slots.default?.[0]?.text
        },
    },
}
</script>

<style scoped>
.oabat-icon {
    align-items: center;
    display: inline-flex;
    font-feature-settings: "liga";
    font-size: 24px;
    justify-content: center;
    letter-spacing: normal;
    line-height: 1;
    position: relative;
    text-indent: 0;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.oabat-icon__svg {
    height: 24px;
    width: 24px;
    fill: currentColor;
}
</style>
