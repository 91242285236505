<template>
    <div class="oabat-header">
        <a href="https://www.oabat.de" target="_blank">
            <img
                class="oabat-header__logo"
                title="Oabat Logo"
                alt="Oabat Logo"
                src="https://minibatwidget.oabat.de/logo-small.svg"
            />
        </a>

        <component
            :is="schoolSlug ? 'a' : 'div'"
            target="_blank"
            :href="
                schoolSlug
                    ? `https://www.oabat.de/schule/${schoolSlug}/minibat`
                    : ''
            "
        >
            <img
                class="oabat-header__minibat"
                title="Minibat Logo"
                alt="Minibat Logo"
                src="https://minibatwidget.oabat.de/logo-minibat.svg"
            />
        </component>
    </div>
</template>

<script>
export default {
    name: "OabatHeader",
    props: {
        schoolSlug: {
            type: String,
            default: "",
        },
    },
}
</script>

<style scoped>
/** @/assets/css/components/_buttons_new.scss */
.oabat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.oabat-header__minibat,
.oabat-header__logo {
    padding: 10px;
}

.oabat-header__logo {
    margin-left: 10px;
    height: 35px;
}

.oabat-header__minibat {
    height: 50px;
}
</style>
