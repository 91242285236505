<template>
    <component
        :is="url ? 'a' : 'div'"
        :href="url"
        type="button"
        class="oabat-btn oabat-btn-black"
        aria-hidden="false"
        target="_blank"
        @click="onClick"
    >
        <span class="btn__content">
            <slot></slot>
        </span>
    </component>
</template>

<script>
export default {
    name: "Button",
    emits: ["click"],
    props: {
        url: {
            type: String,
            default: "",
        },
    },
    methods: {
        onClick() {
            this.$emit("click")
            this.$el.blur()
        },
    },
}
</script>

<style scoped>
/** @/assets/css/components/_buttons_new.scss */

.oabat-btn {
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    position: relative;
    text-decoration: none;
    transition-duration: 0.28s;
    transition-property: box-shadow, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    outline: 0;
    border: none;
    line-height: 50px;

    height: 65px;
    font-size: 18px;
    border-radius: 35px;
    background: #000;
    box-shadow: var(--oabat-widget--shadow);
    font-weight: 300;
    color: #fff;
    -webkit-text-fill-color: #fff;
    caret-color: #fff;
    transition: all 0.1s ease-in-out;
}

.oabat-btn:hover,
.oabat-btn:focus {
    background: rgba(30, 30, 30, 1);
}
.oabat-btn:active {
    background: rgba(50, 50, 50, 1);
}
</style>
