<template>
    <component
        :is="businessUrl ? 'a' : 'div'"
        class="business-logo"
        :class="{ loading: isLoading }"
        :href="businessUrl"
        target="_blank"
    >
        <img v-show="!isLoading" class="business-logo__image" :src="imageSrc" />
    </component>
</template>

<script>
export default {
    name: "BusinessLogo",
    props: {
        business: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        isLoading() {
            return !this.business || Object.keys(this.business).length === 0
        },
        imageSrc() {
            return (
                "https://www.oabat.de" +
                this.getImage({ image: this.business?.logo })
            )
        },
        businessUrl() {
            if (!this.business?.slug) return null
            return `https://www.oabat.de/betriebe/${this.business?.slug}`
        },
    },
    methods: {
        getImage({ image, preferedSize = 3 }) {
            let fileEnding = "jpg"
            let returnImage = null

            if (image && image.file_path && image.file_name) {
                returnImage =
                    image.file_path +
                    image.file_name +
                    "_" +
                    preferedSize +
                    "." +
                    fileEnding
            }
            return returnImage
        },
    },
}
</script>

<style scoped>
/** @/assets/css/components/_buttons_new.scss */
.business-logo {
    width: 55px;
    height: 55px;
    border-radius: 50px;
    box-shadow: var(--oabat-widget--shadow);
    overflow: hidden;
    background: white;
    transition: transform 0.2s;
}

.business-logo:not(.loading):hover {
    transform: scale(1.2);
}

.business-logo.loading {
    background: rgba(0, 0, 0, 0.12);
    position: relative;
}
.business-logo.loading::after {
    background: linear-gradient(
        90deg,
        hsla(0, 0%, 100%, 0),
        hsla(0, 0%, 100%, 0.3),
        hsla(0, 0%, 100%, 0)
    );
    animation: loading 1.5s infinite;
    content: "";
    height: 300%;
    left: -50%;
    position: absolute;
    right: -50%;
    top: -100%;
    transform: translateX(-100%) rotate(50deg);
    z-index: 1;
}

@keyframes loading {
    100% {
        transform: translateX(100%) rotate(50deg);
    }
}

.business-logo__image {
    border-radius: inherit;
    display: inline-flex;
    height: inherit;
    width: inherit;
    object-fit: contain;
}
</style>
